.active-check-mark {
  circle {
    stroke: #3e48a6;
  }
  path {
    fill: #3e48a6;
  }
}

//To add specificity to the selector
.disabled-stepper.disabled-stepper path {
  fill: unset;
  stroke: #b6bcd1;
}
