// Content Mapper Classes
.type-jsx {
  color: #2b2b2b;
  font-size: 20px !important;
}

.stain-flag {
  color: #2b2b2b;
  font-size: 18px !important;
}

.active-stepper-icon {
  stroke: "#3e48a6";
}

.active-stepper-icon {
  circle,
  g {
    stroke: #3e48a6;
  }
  path {
    fill: #3e48a6;
  }
  g > path {
    fill: #3e48a6;
  }
}

.stain-stepper-icon {
  circle,
  g {
    stroke: #3e48a6;
  }
  g > path {
    stroke: #3e48a6;
  }
}

.active-check-mark {
  circle {
    stroke: #3e48a6;
  }
  path {
    fill: #3e48a6;
  }
}

.active-check-mark-error {
  circle {
    stroke: #cd3d3d;
  }
  path {
    fill: #cd3d3d;
  }
}

.disabled-delicate-brand {
  circle {
    stroke: #b6bcd1;
  }
}
