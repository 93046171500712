a.lightbox img {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.lightbox-target {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

.lightbox-target img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

a.lightbox-close {
  display: block;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: white;
  color: black;
  text-decoration: none;
  position: absolute;
  top: -80px;
  right: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.lightbox-target:target {
  opacity: 1;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.lightbox-target:target img {
  max-height: 60%;
  max-width: 80%;
}
