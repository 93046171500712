@import 'assets/styles/variables';
@import 'assets/styles/utils';

body {
  background-color: $color-light-grey;
  font-family: $font-family;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

* {
  --color-label-blue: #111135;
  --color-orange: #e5952c;

  --color-primary: #3e48a6;
  --color-red: #ca3232;
  --color-light-gray: #f8f9fb;
}
